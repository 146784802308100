import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col, Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { device } from "../../utils";

import {
  Title,
  Box,
  Text,
  Select,
  InputGroup,
  Button
} from "../../components/Core";

/* Add some form handling functions */
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
/* Add some form handling functions end */

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: #f7f7fb;
  width: 100%;
`;




const ModalStyled = styled(Modal)`
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    max-height: initial;
    margin: 0;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    padding-top: 15px;
    background: rgba(0, 0, 0, 0.75);
    @media ${device.lg} {
      padding-top: 30px;
    }
  }
`;


const DivStyled = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 400px;
  @media ${device.md} {
    width: 70%;
    height: 650px;
  }
  transition: all 2s ease-out;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 1rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: #fff;
  @media ${device.md} {
    right: 2rem;
  }
`;

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: currentcolor;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
        margin-top:30px;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="#000000"
      ></path>
    </svg>
  </CloseWrapper>
);



const ShapeForm = styled(Box)`
  position: absolute;
  bottom: -58px;
  transform: scale(1.2);
  z-index: -1;
  @media ${device.md} {
    left: 13%;
  }
  @media ${device.lg} {
    bottom: 17px;
    transform: scale(1.5);
    z-index: -1;
    left: 0;
  }
  @media ${device.xl} {
    bottom: -58px;
    transform: scale(1.2);
    z-index: -1;
  }
`;

const cloningFrequency = [
  { value: "", label: "Cloning Frequency?" },
  { value: "", label: "Cloning Frequency?" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
];

const ModalVideo = (props) => {
  const [loading, setLoading] = useState(true);
  const gContext = useContext(GlobalContext);

  const handleClose = () => {
    setLoading(true);
    gContext.toggleVideoModal();
  };

    {/*form handling code*/}
      const [state, setState] = React.useState({})

      const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
      }

      const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const email = document.getElementById("email2").value
      //  const cloningFreq = document.getElementsByName("cloningFrequncy").value
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
          }),
        })
          .then(() => window.analytics.identify({
            email: email,
          //  cloning_frequency: cloningFreq
          }))
          .then(() => window.analytics.track("Request Demo Form Submitted", {
            email: email,
         //   cloning_frequency: cloningFreq
          }))
          .then(() => navigate(form.getAttribute('action')))
          .then(gContext.toggleVideoModal())
          .catch((error) => alert(error))
      }
{/*form handling code end*/}

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.videoModalVisible}
      // onHide={gContext.toggleVideoModal}
    >
      <Modal.Body className="text-center position-relative">
        <CloseButton onClick={handleClose} />

        <div className={`h-100 d-flex align-items-center w-100`}>
          <DivStyled className={`${loading ? "loading" : "loaded"}`}>
          <Container
        css={`
          position: relative;
          z-index: 10;
        `}
      >
        <Row className="justify-content-center">
          <Col
            lg="9"
            xl="8"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            
        <CloseButton onClick={handleClose} />
            <FormStyled>
              <div className="mb-5 text-center">
                <Title className="mb-2">Book an Online Demo</Title>
                <Text>Submit the form and we will arrange an online demo of the isoCell.</Text>
              </div>
              
              <form  
                    name="Book_Demo_Form" 
                    method="post"
                    action="/thank-you-demo/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    >
                    <div className="position-relative">
                    <p hidden>
                      <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="Book_Demo_Form" /> 
                      <InputGroup
                        icon={<i className="icon icon-email-84" />}
                        placeholder="Work Email Address"
                        border="none"
                        type="email" 
                        name="email"
                        id="email2"
                        onChange={handleChange}
                        css={`
                        
                         @media${device.sm}{
                          padding-right: 250px;
                        }
                        `}
                      />
                      <Button
                        css={`
                        margin-top: 1rem;
                        @media${device.sm}{
                          margin-top: 0;
                          position: absolute;
                          top: 50%;
                          right: 5px;
                          transform: translateY(-50%);
                        }
  
                        `}
                        type="submit"
                      >
                        Request Demo
                      </Button>
                    </div>
                  </form>
            </FormStyled>
          </Col>
        </Row>
      </Container>
            
           
               {/*} <FormStyled 
                name="Download_Whitepaper_Form_Modal" 
                method="post"
                action="/thank-you/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                >
                  <p hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="Download_Whitepaper_Form_Modal" /> 
                  <ShapeForm>
                    <img src={imgForm} alt="" className="img-fluid" />
                  </ShapeForm>
                  <Input
                    variant="animation"
                    type="text"
                    name="email"
                    placeholder="Work Email Address"
                    className="mb-4"
                    icon={<i className="icon icon-email-84" />}
                      border="none"
                      type="email" 
                      name="email"
                  />
                  <Input
                    variant="animation"
                    type="text"
                    placeholder="Company name"
                    className="mb-4"
                    icon={<i className="icon icon-email-84" />}
                    border="none"
                    type="company" 
                    name="company"
                  />
                  <Button borderRadius="10px" width="100%" type="submit">
                    Download
                  </Button>
                    </FormStyled> */}
          </DivStyled>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalVideo;
